<script setup lang="ts">
withDefaults(
  defineProps<{
    houseName?: string
  }>(),
  {
    houseName: '',
  },
)

const localePath = useLocalePath()
</script>

<template>
  <div class="flex items-center">
    <nuxt-link
      :to="localePath('realEstateListings')"
      class="flex items-center text-gray-600 hover:text-gray-700 focus:text-gray-700"
    >
      <span class="md:hidden">
        <BaseIcon class="mr-3.5" name="arrowLeft" />
      </span>
      {{ $t('house.transaction.allOurPropertiesForSale') }}
    </nuxt-link>

    <span class="hidden md:flex md:items-center">
      <BaseIcon class="mx-2" name="navArrowRight" />
      <span class="font-bold">{{ houseName }}</span>
    </span>
  </div>
</template>

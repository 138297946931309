<script setup lang="ts">
import type {
  AreasAttributes,
  EquipmentTag,
  RoomAttributes,
} from 'lc-services/types'
import {
  equipmentName,
  formatPoolTooltip,
} from '~/helpers/house/sectionsTags.js'

const props = withDefaults(
  defineProps<{
    room: RoomAttributes
    pools?: AreasAttributes[]
  }>(),
  {
    pools: () => [],
  },
)

const { t } = useI18n()

const formatEquipmentName = (equipment: EquipmentTag) =>
  equipmentName(equipment, { t })
const formatGetPool = (poolId?: string | null) =>
  props.pools.find((p) => p.id === poolId)
</script>

<template>
  <div :id="room.id" class="rounded border border-gray-200 p-6">
    <h3 class="text-lg-bold font-sansSerif text-2xl">
      {{ room.name }}
    </h3>
    <div v-if="room.view">
      {{ $t(`house.views.${room.view}`) }}
    </div>
    <div
      v-for="equipment in room.equipments"
      :key="equipment.id"
      class="house-room__tag"
    >
      <span v-if="equipment" class="lowercase">
        <template v-if="equipment.identifier === 'pool'">
          {{ formatPoolTooltip(formatGetPool(equipment.poolId), { t }) }}
        </template>
        <template v-else>
          {{ formatEquipmentName(equipment) }}
        </template>
      </span>
    </div>
  </div>
</template>

<style>
.house-room__bathroom-icon {
  @apply align-text-top;
}

.house-room__tag {
  @apply lowercase;
}
.house-room__tag::first-letter {
  @apply capitalize;
}
</style>

<script setup lang="ts">
import { getPriceWithCurrency } from '~/helpers/priceHelper'
import type { SupportedCurrencies } from 'lc-services/types'

withDefaults(
  defineProps<{
    currency?: SupportedCurrencies | null
    feePayer: 'seller' | 'buyer'
    price?: number | null
    showPrice?: boolean
  }>(),
  {
    currency: null,
    price: null,
    showPrice: false,
  },
)

const { locale, t } = useI18n()

const buttonCollapseText = ref({
  open: t('global.seeLess'),
  close: t('house.transaction.consultTheLegalInformation'),
})
const openFinancialInformation = ref(false)

const getFormattedSalePrice = (
  salePrice: number | null,
  currency: SupportedCurrencies | null,
) =>
  typeof salePrice === 'number' && currency
    ? getPriceWithCurrency({
        price: salePrice,
        currency,
        i18n: { locale },
      })
    : ''
</script>

<template>
  <div>
    <div class="font-monospace mb-3.5 text-5xl">
      {{ $t('house.transaction.financialInformation') }}
    </div>

    <p class="m-0" data-testid="transaction-price">
      <span class="font-bold">{{ $t('house.transaction.price') }}</span>
      <template v-if="showPrice">
        {{ getFormattedSalePrice(price, currency) }}
      </template>
      <template v-else>
        {{ $t('house.transaction.uponRequest') }}
      </template>
    </p>
    <p class="m-0">
      {{
        $t('house.transaction.agencyFeesChargeable', {
          person: $t(`house.transaction.${feePayer}`),
        })
      }}
    </p>

    <base-collapse
      v-model="openFinancialInformation"
      :button-text="buttonCollapseText"
      class="mt-10"
    >
      <div class="my-4" data-testid="transaction-legal-information">
        <p class="mb-0 font-bold">
          {{ $t('house.transaction.legalInformation.title') }}
        </p>
        <p>
          {{ $t('house.transaction.legalInformation.text1') }}
        </p>
        <p>
          {{ $t('house.transaction.legalInformation.text2') }}<br />
          {{ $t('house.transaction.legalInformation.text3') }}
        </p>

        <p class="mb-0 font-bold">
          {{ $t('house.transaction.agencyFees.title') }}
        </p>
        <p>
          {{ $t('house.transaction.agencyFees.text1') }}<br />
          {{ $t('house.transaction.agencyFees.text2') }}
        </p>
        <p class="italic">
          {{ $t('house.transaction.agencyFees.text3') }}
        </p>
      </div>
    </base-collapse>
  </div>
</template>

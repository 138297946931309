<script setup lang="ts">
import { useURL } from '~/composables/useURL'
import type {
  HouseDestination,
  HouseSaleInformations,
} from '~/types/house/types'

const props = withDefaults(
  defineProps<{
    houseDestinations?: HouseDestination
    houseSaleInformations?: HouseSaleInformations
  }>(),
  {
    houseDestinations: () => ({}) as HouseDestination,
    houseSaleInformations: () => ({}) as HouseSaleInformations,
  },
)

const { t } = useI18n()
const { getApiURL } = useURL()

const openenergyDiagnostics = ref(false)
const buttonCollapseText = ref({
  open: t('global.seeLess'),
  close: t('house.transaction.displayEnergyDiagnostics'),
})

const energyDiagnosticsFilled = computed(
  () =>
    Boolean(props.houseSaleInformations?.energyPerformanceCertificateRate) &&
    Boolean(props.houseSaleInformations?.greenhouseGasRate),
)

const houseIsInFrance = computed(
  () => props.houseDestinations?.country?.name === 'France' || false,
)
const fullPathUrl = (path: string) => getApiURL({ path })
</script>

<template>
  <div v-if="energyDiagnosticsFilled || houseIsInFrance">
    <div class="font-monospace mb-3.5 text-5xl">
      {{ $t('house.transaction.energyDiagnostics') }}
    </div>

    <div v-if="energyDiagnosticsFilled">
      <div class="md:flex">
        <house-transaction-dpe
          class="mb-6 w-full md:mb-0 md:w-1/2"
          :certificate-rate="
            houseSaleInformations.energyPerformanceCertificateRate
          "
          :certificate-kwhep="
            houseSaleInformations.energyPerformanceCertificateKwhep
          "
        />
        <house-transaction-gas
          class="w-full md:w-1/2"
          :gas-rate="houseSaleInformations.greenhouseGasRate"
          :gas-kg="houseSaleInformations.greenhouseGasKgEquivalentCarbonDioxide"
        />
      </div>

      <base-collapse
        v-if="houseSaleInformations.energyDiagnostic"
        v-model="openenergyDiagnostics"
        :button-text="buttonCollapseText"
        class="mt-7"
        content-position="up"
      >
        <img
          :alt="$t('house.transaction.energyDiagnostics')"
          :src="fullPathUrl(houseSaleInformations.energyDiagnostic)"
          class="mb-4"
          data-testid="transaction-dpe-diagnostic-file"
        />
      </base-collapse>
    </div>

    <div v-else-if="houseIsInFrance" data-testid="transaction-dpe-in-progress">
      {{ $t('house.transaction.diagnosticsInProgress') }}
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  equipmentName,
  formatGardenTooltip,
  formatPoolTooltip,
} from '~/helpers/house/sectionsTags.js'
import type { EquipmentTag, AreasAttributes } from 'lc-services/types'

const props = withDefaults(
  defineProps<{
    equipmentTags?: EquipmentTag[]
    pools?: AreasAttributes[]
    title: string
    trackingPage?: string
  }>(),
  {
    equipmentTags: () => [],
    pools: () => [],
    trackingPage: 'Product page',
  },
)
defineSlots<{ default(): any }>()

const { t } = useI18n()
const equipmentsIsOpen = ref(false)

const buttonCollapseText = computed(() => ({
  open: t('house.seeLess'),
  close: t('house.see_all_amenities'),
}))

const visibleEquipments = computed(() => props.equipmentTags.slice(0, 10))
const hiddenEquipments = computed(() => props.equipmentTags.slice(10))

const showTooltip = (equipment: EquipmentTag | AreasAttributes) =>
  equipment.identifier === 'pool' ||
  (equipment.identifier === 'garden' &&
    (equipment as AreasAttributes).gardenType?.length > 1)

const getPool = (poolId: string) => props.pools.find((p) => p.id === poolId)

const formatGardenOrPoolTooltip = (
  equipment: EquipmentTag | AreasAttributes,
) => {
  if (equipment.identifier === 'pool') {
    return formatPoolTooltip(
      getPool(String((equipment as EquipmentTag).poolId)),
      { t },
    )
  } else if (equipment.identifier === 'garden') {
    return formatGardenTooltip((equipment as AreasAttributes).gardenType, { t })
  }
}

const formatEquipmentName = (equipment: EquipmentTag) =>
  equipmentName(equipment, { t })
</script>

<template>
  <div v-if="equipmentTags.length">
    <h2 class="m-0 mb-6 text-2xl">
      {{ title }}
    </h2>

    <base-row>
      <base-col
        v-for="equipment in visibleEquipments"
        :key="equipment.id"
        class="mb-2"
        cols="24"
        sm="12"
      >
        <house-equipment-tooltip
          v-if="showTooltip(equipment)"
          :equipment-name="formatEquipmentName(equipment)"
          :text="formatGardenOrPoolTooltip(equipment)"
        />
        <template v-else>
          {{ formatEquipmentName(equipment) }}
        </template>
      </base-col>
    </base-row>

    <template v-if="hiddenEquipments.length > 0">
      <base-collapse
        v-model="equipmentsIsOpen"
        :button-text="buttonCollapseText"
        content-position="up"
      >
        <base-row>
          <base-col
            v-for="equipment in hiddenEquipments"
            :key="equipment.id"
            class="mb-2"
            cols="24"
            sm="12"
          >
            <house-equipment-tooltip
              v-if="showTooltip(equipment)"
              :equipment-name="formatEquipmentName(equipment)"
              :text="formatGardenOrPoolTooltip(equipment)"
            />
            <template v-else>
              {{ formatEquipmentName(equipment) }}
            </template>
          </base-col>
        </base-row>
      </base-collapse>
    </template>

    <slot />
  </div>
</template>

<script setup lang="ts">
import type { RoomAttributes } from 'lc-services/types'

const props = withDefaults(
  defineProps<{
    roomsList?: RoomAttributes[]
    trackingPage?: string
  }>(),
  {
    roomsList: () => [],
    trackingPage: 'Product page',
  },
)
defineSlots<{ default(): any }>()

const { t } = useI18n()
const roomsIsOpen = ref(false)

const buttonCollapseText = computed(() => ({
  open: t('house.seeLess'),
  close: t('house.see_all_rooms'),
}))

const sortRooms = computed(() =>
  [...props.roomsList].sort((a, b) => a.position - b.position),
)
const visibleRooms = computed(() => sortRooms.value.slice(0, 6))
const hiddenRooms = computed(() => sortRooms.value.slice(6))
</script>

<template>
  <div v-if="roomsList.length">
    <h2 class="m-0 mb-6 text-2xl">
      {{ $t('house.rooms.rooms') }}
    </h2>

    <base-row>
      <base-col
        v-for="room in visibleRooms"
        :key="room.id"
        cols="24"
        sm="8"
        class="flex"
      >
        <house-room
          :room="room"
          class="mx-0 mb-4 mt-0 flex-1"
          data-testid="house-rooms_item-visible"
        />
      </base-col>
    </base-row>

    <template v-if="hiddenRooms.length > 0">
      <base-collapse
        v-model="roomsIsOpen"
        :button-text="buttonCollapseText"
        content-position="up"
        data-testid="house-rooms_collapse"
      >
        <base-row>
          <base-col
            v-for="room in hiddenRooms"
            :key="room.id"
            cols="24"
            sm="8"
            class="flex"
          >
            <house-room
              :room="room"
              class="mx-0 mb-4 mt-0 flex-1"
              data-testid="house-rooms_item-hidden"
            />
          </base-col>
        </base-row>
      </base-collapse>
    </template>

    <slot />
  </div>
</template>
